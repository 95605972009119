import React from 'react'
import './Video.scss'
const Video = ({ data }) => {
    data = data['section__video'];

    return (
        <div className='video max-w-[1440px] mx-auto' id={data.anchor_id}>
            <video src={data.video_url} autoPlay controls />
        </div>
    )
}

export default Video