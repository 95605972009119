import React from 'react'
import './Button.scss'

const Button = (props) => {
  return (
    <a 
        target={props.target}
        className={'button ' + props.className}
        onClick={props.onClick}
        href={props.href}
    >
        {props.children}
    </a>
  )
}

export default Button