import React from 'react'
import './Media.scss'

const Media = ({ data }) => {
    data = data['section__media'];
    return (
        <div className="media -mt-8 lg:-mt-12">
            <div className=' container container--small flex flex-col items-stretch'>
                {data.media_links.map((item, index) =>
                    <a href={item.media_link.url} className="link hyphens-auto flex items-center gap-4 p-6 rounded-lg bg-[#f3f4f6]" target='_blank' key={index}>
                        <svg className='shrink-0 w-6 h-6' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"/><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"/></svg>
                        {item.media_link.title}
                    </a>
                )}
            </div>
        </div>
    )
}

export default Media