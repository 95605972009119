import React from 'react'
import { useState, useRef, useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import './FullwidthSlider.scss'
import SlideToggle from '../../assets/icons/slide-toggle.svg';

const FullwidthSlider = ({ data }) => {
    data = data.fw_slider

    AOS.init()

    const [slideContent, setSlideContent] = useState(0);
    const slideFade = useRef();

    const setSlideIndex = (e) => {
        if (e == true) {
            if (slideContent < data.length - 1) {
                setSlideContent(slideContent + 1);
            } else {
                setSlideContent(0);
            }
        } else {
            if (slideContent > 0) {
                setSlideContent(slideContent - 1);
            }
        }

    }

    useEffect(() => {
        slideFade.current.animate({ opacity: [0, 1] }, 500)
    }, [slideContent]);


    return (
        <div className='fullwidth-slider container mx-auto'>
            {data.map((slide, index) =>
                <>
                    {slideContent == index &&
                        <div className="slide" key={index} ref={slideFade}>
                            <div className="slider-nav flex flex-row justify-between">
                                <span className='title' data-aos="fade-right" data-aos-duration="850">{slide.slide_title}</span>

                                <span className="next-slide flex flex-row items-center" onClick={() => setSlideIndex(true)} data-aos="fade-left" data-aos-duration="850">
                                    <span>Volgende</span>
                                    <img src={SlideToggle} alt="" />
                                </span>
                            </div>
                            <div className="hero" data-aos="fade-in" data-aos-duration="850">
                                <img src={slide.slide_image.url} alt={slide.slide_image.url} />
                            </div>
                        </div>
                    }
                </>
            )}
        </div>
    )
}

export default FullwidthSlider