import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button'
import menuIcon from '../../assets/icons/menu-icon.svg';
import CloseMenu from '../../assets/icons/close-modal.svg';
import './Header.scss'

const Header = ({ data }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [isScrolled, setIsScrolled] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            const isScrollingUp = prevScrollPos > currentScrollPos;
            const isAtTop = currentScrollPos < 10;
            
            // Always show header when at top, show when scrolling up
            setIsVisible(isAtTop || isScrollingUp);
            setIsScrolled(currentScrollPos > 10);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos]);

    function openMenu() {
        document.querySelector('#menu').classList.toggle('active');
        document.body.classList.toggle('menu-open');
    }

    function closeMenu() {
        document.querySelector('#menu').classList.remove('active');
        document.body.classList.remove('menu-open');
    }

    return (
        <header className={`site-header ${isVisible ? 'visible' : 'ishidden'} ${isScrolled ? 'scrolled' : ''}`}>
            <div className="site-header__wrap px-6 lg:px-12 flex justify-between items-center">

                {data.logo && <img className='logo' width={317} src={data.logo.url} alt={data.logo.alt} />}

                <nav id="menu" className='menu'>

                    <img src={CloseMenu} className="close" id="closeMenu" onClick={() => closeMenu()} />

                    {data.navigation &&
                        <ul>
                            {data.navigation.map((item, index) => (
                                <li key={index}><a href={item.link.url} onClick={() => closeMenu()} className='whitespace-nowrap'>{item.link.title}</a></li>
                            ))}
                        </ul>
                    }

                    <div className="flex gap-6 flex-col md:flex-row justify-end md:ml-8">
                        <Button href="https://admin.riverviewworkspacearnhem.nl/wp-content/uploads/2022/05/220420-PRW-Design-Report.pdf" target='_blank' className="bg-transparent text-primary font-bold whitespace-nowrap">
                            Design Report
                        </Button>
                        {data.cta &&
                            <Button href={data.cta.url} className='text-center'>
                                {data.cta.title}
                            </Button>
                        }
                    </div>
                </nav>

                <img src={menuIcon} alt="" className="mobile-menu" id='toggleMenu' onClick={() => openMenu()} />
            </div>
        </header>
    )
}

export default Header