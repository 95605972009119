import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import './DuoImage.scss'

const DuoImage = ({ data }) => {
    data = data['section__duo-img']

    AOS.init();

    return (
        <div className='duo-image ' id={data.anchor_id}>
            <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-12 pb-24">
                <div className="imcon">
                    <img src={data['image-left'].url} alt={data['image-left'].alt} data-aos="fade-right" data-aos-duration="850" />
                </div>
                <div className="imcon">
                    <img src={data['image-right'].url} alt={data['image-right'].alt} data-aos="fade-left" data-aos-duration="850" />
                </div>
            </div>
        </div>
    )
}

export default DuoImage