import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import './Hero.scss'
import SlideToggle from '../../assets/icons/slide-toggle.svg';

const Hero = ({ data }) => {
    AOS.init();

    return (
        <section className='hero relative h-screen max-h-[1000px] min-h-[600px] flex flex-col justify-end'>
            <div className="hero__wrap container--big mx-auto">
                <a href='#LOCATIEGEGEVENS' className="scroll-down-trigger hidden sm:flex flex-row items-center">
                    <span>scroll down</span>
                    <img src={SlideToggle} alt="" />
                </a>

                {data.section__hero.title && <h1 className='hero__title container' dangerouslySetInnerHTML={{ __html: data.section__hero.title }} />}
                <div className="overlay absolute inset-0 w-full h-full  bg-gradient-to-t from-primary via-transparent to-transparent opacity-50 -z-10"></div>
            </div>
            {data.section__hero.image && <img className='newheroimage absolute inset-0 w-full h-full object-cover -z-20' src={data.section__hero.image.url} alt={data.section__hero.image.alt} />}
        </section>
    )
}

export default Hero