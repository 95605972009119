import React from 'react'


import Hero from '../../components/Hero/Hero'
import LabelText from '../../components/LabelText/LabelText'
import Spacer from '../../components/Spacer/Spacer'
import SliderAndPopup from '../../components/SliderAndPopup/SliderAndPopup'
import SliderSurfaceTable from '../../components/SliderSurfaceTable/SliderSurfaceTable'
import DuoImage from '../../components/DuoImage/DuoImage'
import FullwidthSlider from '../../components/FullwidthSlider/FullwidthSlider'
import GridImages from '../../components/GridImages/GridImages'
import Media from '../../components/Media/Media'
import Video from '../../components/Video/Video'

const FlexibleLayout = ({ data }) => {

    return (
        <>
            {data.flex_content.map((item, index) =>
                <>
                    {item.acf_fc_layout === 'section__hero' && <Hero key={index} data={item} />}
                    {item.acf_fc_layout === 'section__label_text' && <LabelText key={index} data={item.section__label_text} />}
                    {item.acf_fc_layout === 'spacer' && <Spacer key={index} data={item.spacer} />}
                    {item.acf_fc_layout === 'section__slider-and-popup' && <SliderAndPopup key={index} data={item} />}
                    {item.acf_fc_layout === 'section__slider_surface_table' && <SliderSurfaceTable key={index} data={item.slider_surface_table} />}
                    {item.acf_fc_layout === 'section__duo-img' && <DuoImage key={index} data={item} />}
                    {item.acf_fc_layout === 'section__fw_slider' && <FullwidthSlider key={index} data={item} />}
                    {item.acf_fc_layout === 'section__grid-images' && <GridImages key={index} data={item} />}
                    {item.acf_fc_layout === 'section__media' && <Media key={index} data={item} />}
                    {item.acf_fc_layout === 'section__video' && <Video key={index} data={item} />}
                </>
            )}
        </>
    )
}

export default FlexibleLayout