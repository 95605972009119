import React from 'react'
import './Footer.scss'

const Footer = ({ data }) => {

    return (
        <footer className='footer' id='FOOTER'>
            <div className="container flex flex-row justify-between">
                <div className="col col-left">
                    <img src={data.logo.url} alt={data.logo.alt} />
                    <div className="content" dangerouslySetInnerHTML={{ __html: data.contact.content }}></div>
                </div>
                <div className="col col-center flex flex-col">
                    <h3>{data.sitemap.title}</h3>
                    {data.sitemap.links.map((item, index) =>
                        <a href={item.link.url} key={index}>{item.link.title}</a>
                    )}
                </div>
                <div className="col col-right">
                    <div className="content" dangerouslySetInnerHTML={{ __html: data.download.content }}></div>
                    <a href={data.download.pdf_download.url} className="btn-white" target='_blank'>{data.download.pdf_download.title}</a>
                </div>
            </div>
        </footer>
    )
}

export default Footer